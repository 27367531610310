import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  cardTitle
} from "../css/index.module.css"
import {
  projectCard,
  projectCardContainer,
  projectCardContent,
  projectCardLocation,
  projectDetail,
  projectTitle,
  legendTitle,
  projectLegend
} from "../css/projects.module.css"
import BrandedDivider from "../components/branded_divider"
import { projects } from "../data/projects"
import PageBanner from "../components/page_banner"
import { SEO } from "../components/seo"


const ProjectsPage = () => {
  return (
    <Layout pageTitle="Projects">
      <PageBanner title="An Extensive Portfolio">
        <StaticImage
          placeholder="blurred"
          alt="EnginuityUSA - Projects"
          src="../images/projects.jpg"
          objectFit="cover"
        />
      </PageBanner>
      <Container className="mt-5 mb-2">
        <Row><h6 className={legendTitle}>Legend</h6></Row>
        <Row>
          <div>
            <span style={{ fontWeight: "bold" }}>D -</span>&nbsp;Design,&nbsp;
            <span style={{ fontWeight: "bold" }}>DB -</span>&nbsp;Design-Build,&nbsp;
            <span style={{ fontWeight: "bold" }}>B -</span>&nbsp;Build,&nbsp;
            <span style={{ fontWeight: "bold" }}>PM -</span>&nbsp;Project Management,&nbsp;
            <span style={{ fontWeight: "bold" }}>CPS -</span>&nbsp;Construction Phase Services&nbsp;
          </div>
        </Row>
      </Container>
      <Container className="mb-5 mt-3">
        <Row>
          {projects.map(section => {
            return (
              <Col lg={6} className="my-3">
                <div className={projectCard}>
                  <div className={projectCardContainer}>
                    <h4 className={cardTitle}><BrandedDivider/>{section.section_title}</h4>
                    <div className={projectCardContent}>
                      {section.projects.map((project, index) => {
                        return (
                          <div key={index} className={projectDetail}>
                            <div className={projectTitle}>{project.title} <span className={projectLegend}>{project.legend || "(D,CPS)"}</span></div>
                            <div className={projectCardLocation}>{project.location}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = () => <SEO title={"Enginuity GC | Projects"}/>

export default ProjectsPage
