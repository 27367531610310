import * as React from "react";
import {secondaryBannerIdent, bannerOverlay} from "../css/index.module.css"

const PageBanner = ({title, children}) => {
  return (
    <div className="d-flex w-100" style={{height: "300px", position: "relative"}}>
      {children}
      <div className={bannerOverlay}><></></div>
      <div className={secondaryBannerIdent}>{title}</div>
    </div>
  )
}

export default PageBanner
