// all legend D,CPS except where defined
export const projects = [{
  section_title: "Churches",
  projects: [
    {title: "First Baptist Indian Rocks Beach : Youth Bldg.", location: "Indian Rocks Beach, FL"},
    {title: "All Saints Anglican Church", location: "Gainesville, FL"},
    {title: "Bradenton Church of God", location: "Bradenton, FL"},
    {title: "Brooksville Weslyan Church", location: "Brooksville, FL"},
    {title: "Calvary Grace Brethren Church", location: "Deltona, FL"},
    {title: "Christian Church in the Wildwood", location: "Weeki Wachi, FL"},
    {title: "Citadel of Hope", location: "Leesburg, FL"},
    {title: "Citra Seventh Day Adventist Church", location: "Citra, FL"},
    {title: "Cornerstone Christian Church", location: "Deltona, FL"},
    {title: "Christ Church", location: "Oxford, FL"},
    {title: "Fellowship Baptist Church", location: "Fellowship, FL"},
  ]
},
  {
    section_title: "Restaurants",
    projects: [
      {title: "Starbucks", location: "Tampa, FL"},
      {title: "Magnanimous Brewery", location: "Tampa, FL"},
      {title: "Brennans", location: "New Orleans, LA"},
      {title: "Fogo de Chao", location: "New Orleans, LA"},
      {title: "Tableau Restaurant", location: "New Orleans, LA"},
      {title: "Willie Jewels", location: "Tampa, FL"},
      {title: "Red Oak Grill", location: "Leesburg, FL"},
      {title: "Red Oak Grill", location: "Clermont, FL"},
      {title: "Dairy Queen", location: "Ocala, FL"},
      {title: "Arby's", location: "Silver Springs, FL"},
      {title: "Arby's", location: "The Villages, FL"},
      {title: "IHOP", location: "Lake City, FL"},
    ]
  },
  {
    section_title: "Government",
    projects: [
      {title: "St. Petersburg Housing Authority", location: "St. Petersburg, FL"},
      {title: "Army Recruitment Office", location: "Largo, FL"},
      {title: "Sumter County Supervisor of Elections", location: "Bushnell, FL"},
      {title: "Sumter County Tax Collector", location: "Bushnell, FL"},
      {title: "Forrest Library", location: "Ocala, FL"},
      {title: "Lady Lake Library", location: "The Villages, FL"},
      {title: "Latter Memorial Library", location: "New Orleans, LA"},
      {title: "Lake Panasoffkee Library", location: "Lake Panasoffkee, FL"},
      {title: "Marion County Fire Stations", location: "13 Stations, Ocala, FL"},
      {title: "Florida Dept. of Juvenile Justice", location: "Avon Park, FL"},
      {title: "University of Florida IFAS", location: "Gainesville, FL"},
      {title: "City of Ocala Police Dept..", location: "Ocala, FL"},
    ]
  },
  {
    section_title: "Offices",
    projects: [
      {title: "Egypt Temple Shrine Headquarters", location: "Tampa, FL"},
      {title: "Canco Headquarters", location: "Lakeland, FL"},
      {title: "Armstrong Homes Corporate Offices", location: "Ocala, FL"},
      {title: "Boyd Development Corpoarate Offices", location: "Ocala, FL"},
      {title: "Vivint Regional Sales and Training Offices", location: "Tampa, FL"},
      {title: "Cobb Commerce Park", location: "Umatilla, FL"},
      {title: "Carlyle Law Firm", location: "Leesburg, FL"},
      {title: "Dana Center", location: "The Villages, FL"},
      {title: "Doherty Professional Building", location: "Leesburg, FL"},
      {title: "First American Title", location: "Leesburg, FL"},
      {title: "Brock Services", location: "Geismer LA"}
    ]
  },
  {
    section_title: "Medical / Dental",
    projects: [
      {title: "VIP Care", location: "Various Locations"},
      {title: "Advanced Dental", location: "The Villages, FL"},
      {title: "Diagnostic Outpatient Centers of Ocala", location: "Ocala, FL"},
      {title: "Dr. Jackson Dentist Office", location: "Ocala, FL"},
      {title: "Dr. Khanna Dentist Office", location: "Ocala, FL"},
      {title: "Dr. Uche Dentist Office", location: "Ocala, FL"},
      {title: "St. Tammany Parish Hospital", location: "Covington, LA"},
      {title: "Smile Design Dentistry", location: "Orlando, FL"},
      {title: "Smile Design Dentistry", location: "Sarasota, FL"},
    ]
  },
  {
    section_title: "Retail",
    projects: [
      {title: "CVS", location: "Various Locations"},
      {title: "Circle K", location: "Various Locations"},
      {title: "T-Mobile", location: "Lutz, FL"},
      {title: "Bargains and Treasures", location: "The Villages, FL"},
      {title: "Boyd Market Square", location: "Holly Hill, FL"},
      {title: "Boyd Market Square", location: "Silver Springs, FL"},
      {title: "Boyd Market Square", location: "Stonecrest, FL"},
      {title: "Caldwell Tractor Supply", location: "Ocala, FL"},
      {title: "Mac’s Custom Meats and Deli", location: "Lutz, FL", legend: "(DB)"},
    ]
  },
  {
    section_title: "High Rise",
    projects: [
      {title: "Nopsi Hotel - 10 stories", location: "New Orleans, LA"},
      {title: "World Trade Center - 33 stories", location: "New Orleans, LA"},
      {title: "The Standard Condominiums - 14 stories", location: "New Orleans, LA"},
      {title: "Canopy Hotel - 12 stories", location: "New Orleans, LA"},
      {title: "Contemporary Hotel, Disney World", location: "Lake Buena Vista, FL"},
    ]
  },
  {
    section_title: "Sports & Recreation",
    projects: [
      {title: "Wrigley Fields", location: "Ocala, FL"},
      {title: "Laser Ops", location: "Tampa, FL"},
      {title: "New Life Tabernacle - Family Life Center", location: "Brandon, FL"},
      {title: "Cedar Key Park", location: "Cedar Key, FL"},
      {title: "Debary Family Life Center", location: "Debary, FL"},
    ]
  },
  {
    section_title: "Ports & Terminals",
    projects: [
      {title: "Burnside Terminal", location: "Burnside, LA", legend: "(PM)"},
      {title: "Carnival Cruise Line Terminal", location: "Cozumel, Mexico"},
      {title: "Port Everglades", location: "Ft. Lauderdale, FL", legend: "(B)"},
      {title: "Port Canaveral", location: "Cape Canaveral, FL"},
      {title: "Port of Tampa", location: "Tampa, FL"},
      {title: "Port of Jacksonville", location: "Jacksonville, FL"},
      {title: "Electro-Coal Transfer", location: "New Orleans, LA"},
    ]
  },
  {
    section_title: "Education",
    projects: [
      {title: "Warner University : Athletic Bldg.", location: "Lake Wales, FL"},
      {title: "Warner University : Agriculture Bldg.", location: "Lake Wales, FL"},
      {title: "Warner University : Marine Bldg.", location: "Lake Wales, FL"},
      {title: "Warner University : Greenhouse", location: "Lake Wales, FL"},
    ]
  }, {
    section_title: "Pharmaceutical",
    projects: [
      {title: "Nephron Pharmaceuticals", location: "Orlando, FL"}
    ]
  }
]
